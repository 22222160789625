import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import HeadingBg from "@images/slider-img1.jpg"
import PageHeading from "@components/page-heading"
import { Container, Row, Col } from "reactstrap"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const SampleSizeGuidelines = () => {
  return (
    <Layout>
      <SEO title="Sample Size Guidelines" description="" />
      <PageHeading heading="Sample Size Guidelines" BgImg={HeadingBg} />
      <div className="page-section text-center">
        <Container>
          <object
            data="/../../assets/sample-size-guidelines.pdf"
            type="application/pdf"
            width="100%"
            className="size-guidline"
          >
            <p>
              Unable to display PDF file.{" "}
              <a href="../../static/assets/sample-size-guidelines.pdf">
                Download
              </a>{" "}
              instead.
            </p>
          </object>
          <a
            className="btn btn-alt mt-5"
            href="/../../assets/sample-size-guidelines.pdf"
            target="_blank"
          >
            Download
          </a>
        </Container>
      </div>
    </Layout>
  )
}

export default SampleSizeGuidelines
